<template>
  <top-agent-self-withdrawal v-if="this.$store.getters.userRole === 'top_agent'" />
  <agency-self-withdrawal v-else-if="this.$store.getters.userRole === 'agency'" />
  <merchant-self-withdrawal v-else-if="this.$store.getters.userRole === 'merchant'" />
</template>

<script>
import TopAgentSelfWithdrawal from '@/views/withdrawals/top_agent_self/index'
import AgencySelfWithdrawal from '@/views/withdrawals/agency_self/index'
import MerchantSelfWithdrawal from '@/views/withdrawals/merchant_self/index'

export default {
  name: 'SideMenu',
  components: {
    TopAgentSelfWithdrawal,
    AgencySelfWithdrawal,
    MerchantSelfWithdrawal
  }
}
</script>
